export const clientNameFooter = "RE MOZZINI";
export const titleMain = "Bem Vinda(o) ao REyoga";
export const subtitleMain = "Caminhos do Autoconhecimento";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "RENATA RISCH MOZZINI";
export const nomeFornecedor = "RENATA RISCH MOZZINI";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/remozzini/",
    profile: "@remozzini"
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=5511950437654",
    number: "(11) 95043-7654"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send?phone=5511950437654",
  },
  {
    title: "APP Yoganaya School",
    link: "https://app.yoganaya.com.br",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/reyoga/id1641520769"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.reyoga_mobile.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];